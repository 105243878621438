import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  colors: {
    background: `#FFF6E5`,
    },
  fonts: {
    body: '"Kreon"',
    monospace: 'Courier Prime, monospace',
  },
});

export default theme;